import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import HeaderBanner from "../components/headerbanner"
import { Link } from "gatsby"
import "../styles/style.scss"
import "../styles/csstyle.scss"
import Img from "gatsby-image"
export default function BrandPage(props) {
  return (
    <div>
      <Helmet>
        <title>Brands</title>
      </Helmet>
      <Layout>
        <HeaderBanner>
          <h1>Our Partner Brands</h1>
        </HeaderBanner>
        <div className="container">
          <br />
          <div className="row brandsLogo">
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/wework" className="brandBox">
                <Img
                  fluid={props.data.wework.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="WeWork"
                ></Img>
                <p>Explore {props.data.weworkcount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/cowrks" className="brandBox">
                <Img
                  fluid={props.data.cowrks.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="Cowrks"
                ></Img>
                <p>Explore {props.data.cowrkscount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/awfis" className="brandBox">
                <Img
                  fluid={props.data.awfis.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="AWFIS"
                ></Img>
                <p>Explore {props.data.awfiscount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/91springboard" className="brandBox">
                <Img
                  fluid={props.data.springboard.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="91Springboard"
                ></Img>
                <p>Explore {props.data.springboardcount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/innov8" className="brandBox">
                <Img
                  fluid={props.data.innov8.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="Innov8"
                ></Img>
                <p>Explore {props.data.innov8count.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/mybranch" className="brandBox">
                <Img
                  fluid={props.data.mybranch.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="MyBranch"
                ></Img>
                <p>Explore {props.data.mybranchcount.totalCount} spaces</p>
              </Link>
            </div>
            <br /> <br />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query BrandPageImages {
    weworkcount: allListings(
      filter: { operatorName: { eq: "WeWork" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    cowrkscount: allListings(
      filter: { operatorName: { eq: "Cowrks" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    awfiscount: allListings(
      filter: { operatorName: { eq: "AWFIS" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    springboardcount: allListings(
      filter: { operatorName: { eq: "91Springboard" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    innov8count: allListings(
      filter: { operatorName: { eq: "Innov8" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    mybranchcount: allListings(
      filter: { operatorName: { eq: "MyBranch" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    springboard: file(relativePath: { eq: "brands/91springboard.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    awfis: file(relativePath: { eq: "brands/awfis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cowrks: file(relativePath: { eq: "brands/cowrks.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    innov8: file(relativePath: { eq: "brands/innov8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mybranch: file(relativePath: { eq: "brands/mybranch.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wework: file(relativePath: { eq: "brands/wework.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
